import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SpeciesList from "../type/speciesList";
import { ResponsibleType } from "../type/responsibleType";

type RegisterFilter = {
  collaboratorType: string;
  dateFrom: string;
  dateTo: string;
  treeSpecies: SpeciesList | null | undefined;
  collaborator: ResponsibleType | null | undefined;
  is_out_of_country: boolean;
  incidents: SpeciesList[];
};
const initialState: {
  filterItem: RegisterFilter;
  url: string;
  filterSet: boolean;
  deleteFilter:
    | "collaboratorType"
    | "date"
    | "treeSpecies"
    | "collaboratorName"
    | "is_out_of_country"
    | "incidents"
    | "";
} = {
  filterItem: {
    collaboratorType: "",
    collaborator: null,
    dateFrom: "",
    dateTo: "",
    treeSpecies: null,
    is_out_of_country: false,
    incidents: [],
  },
  url: "/?",
  filterSet: false,
  deleteFilter: "",
};

const registerFilterSlice = createSlice({
  name: "registerFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<RegisterFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeDate: (state) => {
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.deleteFilter = "date";
    },
    removeTreeSpecies: (state) => {
      state.filterItem.treeSpecies = null;
      state.deleteFilter = "treeSpecies";
    },
    removeCollaboratorName: (state) => {
      state.filterItem.collaborator = null;
      state.deleteFilter = "collaboratorName";
    },
    removeCollaboratorType: (state) => {
      state.filterItem.collaboratorType = "";
      state.filterItem.collaborator = null;
      state.deleteFilter = "collaboratorType";
    },
    removeOutOfCountry: (state) => {
      state.filterItem.is_out_of_country = false;
      state.deleteFilter = "is_out_of_country";
    },
    removeIncidents: (state) => {
      state.filterItem.incidents = [];
      state.deleteFilter = "incidents";
    },
    setURL: (state) => {
      let incident = "";
      console.log("state", state.filterItem);
      if (state.filterItem.incidents.length !== 0) {
        state.filterItem.incidents.forEach((c) => {
          incident += `incidents=${c.slug}&`;
        });
      }
      state.url = `/?date_from=${state.filterItem.dateFrom}&date_to=${
        state.filterItem.dateTo
      }&responsible_category=${
        state.filterItem.collaboratorType
      }&responsible_type=${
        state.filterItem.collaborator ? state.filterItem.collaborator.slug : ""
      }&species=${
        state.filterItem.treeSpecies ? state.filterItem.treeSpecies.slug : ""
      }&out_of_country=${state.filterItem.is_out_of_country}&${incident}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.collaborator = null;
      state.filterItem.treeSpecies = null;
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.filterItem.collaboratorType = "";
      state.deleteFilter = "";
      state.filterItem.is_out_of_country = false;
      state.filterItem.incidents = [];
    },
    isFilterSet: (state) => {
      if (
        !state.filterItem.collaborator &&
        state.filterItem.dateFrom === "" &&
        state.filterItem.dateTo === "" &&
        !state.filterItem.treeSpecies &&
        state.filterItem.collaboratorType === "" &&
        !state.filterItem.is_out_of_country &&
        state.filterItem.incidents.length === 0
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const registerFilterAction = registerFilterSlice.actions;
export default registerFilterSlice;
