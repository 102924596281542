import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import InfoPopover from "./InfoPopover";
import { RegisterRecord } from "../../../type/register";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import ActionItem from "../../Global/ActionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faSkull,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { profileInfo } from "../../../store";
import IncidentInfo from "./IncidentInfo";
import { faLocationExclamation } from "@fortawesome/pro-regular-svg-icons";

const RegisterTableItem: React.FC<{
  registerItem: RegisterRecord;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const { t, i18n } = useTranslation();

  const index = props.lastIndex >= 0 ? true : false;
  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/${props.registerItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);
  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const deleteAction = () => {
    isShowDelete(true);
  };
  const hide = () => {
    isShowDelete(false);
  };
  const speciesFr =
    props.registerItem.species_name_transes.fr === ""
      ? props.registerItem.species_name_transes.en
      : props.registerItem.species_name_transes.fr;
  const originFr =
    props.registerItem.origin_transes.fr === ""
      ? props.registerItem.origin_transes.en
      : props.registerItem.origin_transes.fr;
  return (
    <Fragment>
      <TableCell>
        <div className="w-[100px] flex flex-row items-center">
          {props.registerItem.is_out_of_country && (
            <span
              title={`${t(
                "Register.The submitted location is out of the country."
              )}`}
            >
              <FontAwesomeIcon
                className="text-[#414141] mr-2"
                icon={faLocationExclamation}
              />
            </span>
          )}
          <Link
            className="hover:text-ph-btn"
            to={`/registers/view-register/${props.registerItem.slug}`}
          >
            {props.registerItem.code}{" "}
          </Link>
          {props.registerItem.is_dead && (
            <FontAwesomeIcon
              className="text-[#414141] pl-3"
              title={`${t("Growth history.Dead")}`}
              icon={faSkull}
            />
          )}
        </div>
      </TableCell>
      <TableCell>{props.registerItem.date_of_planting}</TableCell>
      <TableCell align="left">
        {i18n.language === "en"
          ? speciesFr
          : props.registerItem.species_name_transes.en}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "en"
          ? originFr
          : props.registerItem.origin_transes.en}
      </TableCell>
      <TableCell>
        <div className=" flex items-center">
          {" "}
          {props.registerItem.responsible_name}
          {props.registerItem.description_transes.en !== "" && (
            <InfoPopover
              index={index}
              scroll={scroll}
              desc={props.registerItem.description_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell>
        {i18n.language === "fr"
          ? props.registerItem.responsible_category_transes.fr
          : props.registerItem.responsible_category_transes.en}
      </TableCell>
      <TableCell>{props.registerItem.responsible_type}</TableCell>
      <TableCell>
        {props.registerItem.incidents.length > 0 && (
          <IncidentInfo
            incidents={props.registerItem.incidents}
            index={index}
            scroll={scroll}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {props.registerItem.is_replanting ? (
          <FontAwesomeIcon
            className="text-lg text-ph-btn"
            icon={faCircleCheck}
          />
        ) : (
          <FontAwesomeIcon
            className="text-lg text-[#D2202A]"
            icon={faCircleXmark}
          />
        )}
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={profile.role === "O" ? false : true}
          deleteAction={deleteAction}
          editLink={`/registers/edit-register/${props.registerItem.slug}`}
          view={t("Register.View register")}
          viewLink={`/registers/view-register/${props.registerItem.slug}`}
          viewHistory={t("Register.View growth history")}
          historyLink={`/registers/growth-history/${props.registerItem.slug}`}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          type={t("Register.Register")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default RegisterTableItem;
