import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { TreeSpeciesRecord } from "../../../type/treeSpecies";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";

const SpeciesTableItem: React.FC<{
  speciesItem: TreeSpeciesRecord;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const index = props.lastIndex >= 0 ? true : false;
  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/species/${props.speciesItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      props.fetchData();
      isShowDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (props.speciesItem.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(t("Message.Invalid species delete"));
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  const name =
    props.speciesItem.name_transes.fr === ""
      ? props.speciesItem.name_transes.en
      : props.speciesItem.name_transes.fr;
  const scientificName =
    props.speciesItem.scientific_name_transes.fr === ""
      ? props.speciesItem.scientific_name_transes.en
      : props.speciesItem.scientific_name_transes.fr;
  const botanicalFamily =
    props.speciesItem.botanical_family_transes?.fr === ""
      ? props.speciesItem.botanical_family_transes?.en
      : props.speciesItem.botanical_family_transes?.fr;
  const type =
    props.speciesItem.type_transes.fr === ""
      ? props.speciesItem.type_transes.en
      : props.speciesItem.type_transes.fr;
  const abundance =
    props.speciesItem.abundance_transes.fr === ""
      ? props.speciesItem.abundance_transes.en
      : props.speciesItem.abundance_transes.fr;
  const active =
    i18n.language === "en"
      ? props.speciesItem.is_active_transes.en
      : props.speciesItem.is_active_transes.fr;
  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr" ? name : props.speciesItem.name_transes.en}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "fr"
          ? scientificName
          : props.speciesItem.scientific_name_transes.en}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "fr"
          ? botanicalFamily
          : props.speciesItem.botanical_family_transes?.en}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "fr" ? type : props.speciesItem.type_transes.en}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "fr"
          ? abundance
          : props.speciesItem.abundance_transes.en}
      </TableCell>
      <TableCell>
        <p
          className={`${
            props.speciesItem.is_active_transes.en === "Active"
              ? "bg-[#CDE6F0] text-[#066993]"
              : "bg-[#FFDBDE] text-[#D2202A]"
          } text-center w-16 py-1 rounded-xl`}
        >
          {active}
        </p>
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          deleteAction={deleteAction}
          editLink={`/setting/tree-species/edit-species/${props.speciesItem.slug}`}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          type={t("Tree Species.Tree species")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default SpeciesTableItem;
