import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import SpeciesList from "../../../type/speciesList";
import BreadCrumb from "../../Global/BreadCrumb";
import AddRegisterForm from "../AddRegister/AddRegisterForm";
import { ResponsibleType } from "../../../type/responsibleType";
import EditSlider from "../../Global/EditSlider";
import Loading from "../../Global/Loading";
import PlantingList from "../../../type/plantingList";

const EditRegister = () => {
  const [selectOption, setSelectOption] = useState<{
    treeSpecies: SpeciesList[];
    grp: ResponsibleType[];
    ins: ResponsibleType[];
    ind: ResponsibleType[];
    fml: ResponsibleType[];
    incidents: SpeciesList[];
    origins: SpeciesList[];
    plantings: PlantingList[];
  }>({
    treeSpecies: [],
    grp: [],
    ins: [],
    ind: [],
    fml: [],
    incidents: [],
    origins: [],
    plantings: [],
  });
  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const {
    fetchData: editPlanting,
    response: editResponse,
    error,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "PATCH",
    false,
    "",
    true,
    true
  );

  const {
    response: getEditResponse,
    fetchData: getPlanting,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { fetchData: getEventId, response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    false,
    false
  );

  const { response: treeSpeciesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: plantingAll } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/plantings/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: grpRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=grp",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ind",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ins",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: fmlRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=fml",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: originRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/origins/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (treeSpeciesRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          treeSpecies: treeSpeciesRes.data,
        };
      });
    }
    if (plantingAll) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          plantings: plantingAll.data,
        };
      });
    }
    if (grpRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          grp: grpRes.data,
        };
      });
    }
    if (indRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ind: indRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
    if (fmlRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          fml: fmlRes.data,
        };
      });
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incidents: incidentRes.data,
        };
      });
    }
    if (originRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          origins: originRes.data,
        };
      });
    }
  }, [treeSpeciesRes, indRes, insRes, grpRes, fmlRes, incidentRes, originRes]);

  useEffect(() => {
    getPlanting({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (getEditResponse) {
      getEventId({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  const addOrganizerName = (type: string, value: ResponsibleType) => {
    if (type === "grp") {
      let grp = selectOption.grp;
      grp.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        ind: selectOption.ind,
        ins: selectOption.ins,
        grp: grp,
        fml: selectOption.fml,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
        plantings: selectOption.plantings,
      });
    } else if (type === "fml") {
      let fml = selectOption.fml;
      fml.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        plantings: selectOption.plantings,

        fml: fml,
        ins: selectOption.ins,
        grp: selectOption.grp,
        ind: selectOption.ind,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
      });
    } else if (type === "ins") {
      let ins = selectOption.ins;
      ins.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        ind: selectOption.ind,
        ins: ins,
        grp: selectOption.grp,
        fml: selectOption.fml,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
        plantings: selectOption.plantings,
      });
    }
  };

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Register.Edit register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${breadCrumb?.data.date}-${
              breadCrumb?.data.department_name
            }-${
              i18n.language === "fr"
                ? breadCrumb?.data.species_name_transes.fr ||
                  breadCrumb?.data.species_name_transes.en
                : breadCrumb?.data.species_name_transes.en
            }-${breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Register.Edit register"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddRegisterForm
              addOrganizer={addOrganizerName}
              selectOptions={selectOption}
              send={editPlanting}
              sendResponse={editResponse}
              sendError={error}
              getInfo={getEditResponse}
            />
          </div>
          <EditSlider
            images={["/img/Add-register-1.svg", "/img/Add-register-2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default EditRegister;
