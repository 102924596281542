import Slider from "../../Global/Slider";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SpeciesList from "../../../type/speciesList";
import useAxios from "../../../hook/useAxios";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import AddRegisterForm from "./AddRegisterForm";
import { ResponsibleType } from "../../../type/responsibleType";
import PlantingList from "../../../type/plantingList";

const AddRegister = () => {
  const [selectOption, setSelectOption] = useState<{
    treeSpecies: SpeciesList[];
    grp: ResponsibleType[];
    ins: ResponsibleType[];
    ind: ResponsibleType[];
    fml: ResponsibleType[];
    incidents: SpeciesList[];
    origins: SpeciesList[];
    plantings: PlantingList[];
  }>({
    treeSpecies: [],
    ind: [],
    ins: [],
    grp: [],
    fml: [],
    incidents: [],
    origins: [],
    plantings: [],
  });
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const { fetchData, response, error } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/new/`,
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { response: treeSpeciesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    true,
    false
  );

  const { response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/breadcrumb-data/`,
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: grpRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=grp",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: fmlRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=fml",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ind",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ins",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: originRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/origins/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const addOrganizerName = (type: string, value: ResponsibleType) => {
    if (type === "grp") {
      let grp = selectOption.grp;
      grp.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        ind: selectOption.ind,
        ins: selectOption.ins,
        grp: grp,
        fml: selectOption.fml,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
        plantings: selectOption.plantings,
      });
    } else if (type === "fml") {
      let fml = selectOption.fml;
      fml.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        fml: fml,
        ins: selectOption.ins,
        grp: selectOption.grp,
        ind: selectOption.ind,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
        plantings: selectOption.plantings,
      });
    } else if (type === "ins") {
      let ins = selectOption.ins;
      ins.push(value);
      setSelectOption({
        treeSpecies: selectOption.treeSpecies,
        ind: selectOption.ind,
        ins: ins,
        grp: selectOption.grp,
        fml: selectOption.fml,
        incidents: selectOption.incidents,
        origins: selectOption.origins,
        plantings: selectOption.plantings,
      });
    }
  };

  useEffect(() => {
    if (treeSpeciesRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          treeSpecies: treeSpeciesRes.data,
        };
      });
    }
    if (grpRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          grp: grpRes.data,
        };
      });
    }
    if (indRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ind: indRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
    if (fmlRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          fml: fmlRes.data,
        };
      });
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incidents: incidentRes.data,
        };
      });
    }
    if (originRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          origins: originRes.data,
        };
      });
    }
  }, [treeSpeciesRes, indRes, insRes, grpRes, fmlRes, incidentRes, originRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Plantings.Add register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Plantings.Plantings"), url: "/plantings" },
          {
            name: `${breadCrumb?.data.date}-${breadCrumb?.data.department_name}`,
            url: `/registers/${id}`,
          },
          { name: t("Plantings.Add register"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddRegisterForm
            addOrganizer={addOrganizerName}
            selectOptions={selectOption}
            send={fetchData}
            sendResponse={response}
            sendError={error}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] sc:h-[529px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <Slider
            text={true}
            images={["/img/Add-register-1.svg", "/img/Add-register-2.svg"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddRegister;
