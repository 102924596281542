import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OldFile from "../../Global/OldFile";
import Video from "../AddPlanting/Video";
import { Link } from "react-router-dom";

const PlantingView: React.FC<{
  getInfo: any;
}> = (props) => {
  const [planting, setPlanting] = useState<any>();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (props.getInfo) {
      setPlanting(props.getInfo.data);
    }
  }, [props.getInfo]);

  return (
    <Fragment>
      {planting && (
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <div className="bg-[#F7F7F7] rounded-md p-5">
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("Tree Species.Name")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {i18n.language === "fr"
                  ? planting.name_transes.fr || planting.name_transes.en
                  : planting.name_transes.en}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("Plantings.Date of planting")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {planting.date}
              </p>
            </div>
            {planting.project_country && (
              <div className="flex">
                <p className="text-sm text-[#414141] w-40 py-3">
                  {t("Plantings.Country")}
                </p>
                <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                  {planting.project_country}
                </p>
              </div>
            )}
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("PlantingFilter.Province")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {planting.department_name}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("Plantings.District")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {planting.municipality}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("Plantings.Community")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {planting.community}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-[#414141] w-40 py-3">
                {t("Plantings.Planted tree(s)")}
              </p>
              <p className="text-sm text-[#414141] font-medium truncate pl-4 py-3">
                {planting.planted_trees}
              </p>
            </div>
          </div>
          <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
            <p className="text-sm text-[#414141] font-semibold">
              {t("Plantings.Organizer")}
            </p>
            <div className="flex mt-3">
              <div>
                <p className="text-sm py-3 text-[#414141] font-semibold">
                  {t("Tree Species.Type")}
                </p>
                {planting.organizers.map((organizer: any, index: number) => (
                  <p key={index} className="text-sm py-3">
                    {organizer.type === "ind"
                      ? t("Register.Individual")
                      : organizer.type === "grp"
                      ? t("Register.Group")
                      : organizer.type === "fml"
                      ? t("Register.Family")
                      : t("Register.Institution")}
                  </p>
                ))}
              </div>
              <div className="pl-16">
                <p className="text-sm py-3 text-[#414141] font-semibold">
                  {t("Tree Species.Name")}
                </p>
                {planting.organizers.map((organizer: any, index: number) => (
                  <p key={organizer.name} className="text-sm py-3">
                    {organizer.name}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
            <p className="text-sm text-[#414141] font-semibold">
              {t("PlantingFilter.Participants")}
            </p>
            <div className="flex mt-3">
              <div>
                <p className="text-sm py-3">{t("Workshops.PSA students")}</p>
                <p className="text-sm py-3">{t("Workshops.School students")}</p>
                <p className="text-sm py-3">{t("Workshops.PSA tutors")}</p>
                <p className="text-sm py-3">{t("Workshops.School teachers")}</p>
                <p className="text-sm py-3">{t("Workshops.Farmers")}</p>
                <p className="text-sm py-3">{t("Workshops.Promoters")}</p>
                <p className="text-sm py-3">
                  {t("Workshops.Junior youth parents")}
                </p>
                <p className="text-sm py-3">
                  {t("Workshops.Community leaders")}
                </p>
                <p className="text-sm py-3">
                  {t("Workshops.Other participants")}
                </p>
              </div>
              <div className="pl-16">
                <p className="text-sm py-3">{planting.psa_students}</p>
                <p className="text-sm py-3">{planting.school_students}</p>
                <p className="text-sm py-3">{planting.psa_tutors}</p>
                <p className="text-sm py-3">{planting.school_tutors}</p>
                <p className="text-sm py-3">{planting.farmers}</p>
                <p className="text-sm py-3">{planting.promoters}</p>
                <p className="text-sm py-3">{planting.junior_youth_parents}</p>
                <p className="text-sm py-3">{planting.community_leaders}</p>
                <p className="text-sm py-3">{planting.other_participants}</p>
              </div>
            </div>
          </div>
          {(planting.files.length > 0 || planting.video_files.length > 0) && (
            <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
              <p className="text-sm text-[#414141] font-semibold mb-4">
                {t("AddPlanting.Relevant resources")}
              </p>
              {planting.files.map((file: any) => (
                <OldFile
                  delete={false}
                  address={file.file_address}
                  id={file.id}
                  removeOldFile={() => {}}
                  key={file.id}
                />
              ))}
              {planting.video_files.map((video: any, index: number) => (
                <Video
                  key={index}
                  delete={false}
                  removeAction={() => {}}
                  title={video.title}
                  url={video.link}
                  videoIndex={index}
                />
              ))}
            </div>
          )}
          {(planting.description_transes.en !== "" ||
            planting.description_transes.fr !== "") && (
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm text-[#414141]">
                  {t("Tree Species.Description")}
                </p>
              </div>
              <p className="text-sm pl-5 text-[#414141] max-w-[420px] break-words ">
                {i18n.language === "en"
                  ? planting.description_transes.fr === ""
                    ? planting.description_transes.en
                    : planting.description_transes.fr
                  : planting.description_transes.en}
              </p>
            </div>
          )}
          {(planting.comment_transes.en !== "" ||
            planting.comment_transes.fr !== "") && (
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm text-[#414141]">
                  {t("Plantings.Comment")}
                </p>
              </div>
              <p className="text-sm pl-5 text-[#414141] max-w-[420px] break-words ">
                {i18n.language === "en"
                  ? planting.comment_transes.fr === ""
                    ? planting.comment_transes.en
                    : planting.comment_transes.fr
                  : planting.comment_transes.en}
              </p>
            </div>
          )}
          <Link
            to="/plantings"
            className="main-btn text-sm px-6 py-2 mt-5 float-right"
          >
            {t("Plantings.Back to plantings")}
          </Link>
        </div>
      )}
    </Fragment>
  );
};

export default PlantingView;
