import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import { GrowthStage } from "../../../type/growthStage";

const BotanicalFamilyTableItem: React.FC<{
  botanicalFamilies: GrowthStage;
  editLink: string;
  link: string;
  deleteMessage: string;
  title: string;
  featured_icon?: string;
  fetchData: () => void;
}> = (props) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `${props.link}/${props.botanicalFamilies.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR !== null) {
      isShowDelete(false);
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (props.botanicalFamilies.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(props.deleteMessage);
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const incident =
    props.botanicalFamilies.name_transes.fr === ""
      ? props.botanicalFamilies.name_transes.en
      : props.botanicalFamilies.name_transes.fr;

  const desc =
    props.botanicalFamilies.description_transes.fr === ""
      ? props.botanicalFamilies.description_transes.en
      : props.botanicalFamilies.description_transes.fr;

  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr"
          ? incident
          : props.botanicalFamilies.name_transes.en}
      </TableCell>
      <TableCell>
        <div className="max-w-[600px] overflow-x-auto break-words">
          {i18n.language === "en"
            ? desc
            : props.botanicalFamilies.description_transes.fr}
        </div>
      </TableCell>
      {props.link.includes("incidents") && (
        <TableCell align="center">
          {props.featured_icon && (
            <div className="flex justify-center">
              <img
                src={props.featured_icon}
                alt="incident"
                className="w-[14px] h-[14px] mr-3 object-cover"
              />
            </div>
          )}
        </TableCell>
      )}
      <TableCell>
        {props.title !== "Botanical family" && (
          <p
            className={`${
              props.botanicalFamilies.is_active_transes.en === "Active"
                ? "bg-[#CDE6F0] text-[#066993]"
                : "bg-[#FFDBDE] text-[#D2202A]"
            } text-center w-16 py-1 rounded-xl`}
          >
            {i18n.language === "en"
              ? props.botanicalFamilies.is_active_transes.en
              : props.botanicalFamilies.is_active_transes.fr}
          </p>
        )}
      </TableCell>

      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`${props.editLink}/${props.botanicalFamilies.slug}`}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert type={props.title} hide={hide} deleteAction={deleteItem} />
      )}
    </Fragment>
  );
};

export default BotanicalFamilyTableItem;
