import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import speciesList from "../../../type/speciesList";
import i18n from "../../../i18n";
const AppliedIncidents: React.FC<{
  type: string;
  value: speciesList[];
  removeFilter: () => void;
}> = ({ type, value, removeFilter }) => {
  const removerFilter = () => {
    removeFilter();
  };
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-black">
        {type}:{" "}
        {value.map((c, index) => (
          <span key={c.slug}>
            {i18n.language === "en"
              ? c.name_transes.en === ""
                ? c.name_transes.fr
                : c.name_transes.en
              : c.name_transes.fr}
            {index < value.length - 1 ? ", " : ""}
          </span>
        ))}
      </p>

      <span className="relative top-[2px] pl-2" onClick={removerFilter}>
        <FontAwesomeIcon icon={faXmark} />
      </span>
    </div>
  );
};

export default AppliedIncidents;
