import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";

const TreesDetail: React.FC<{
  num: number;
  trees: { species_name_transes: TranslationField; register_count: number }[];
  index?: boolean;
  scroll?: boolean;
}> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="tree-number">
      {props.num}
      <table
        className={` ${
          props.trees.length > 8 ? "h-[15rem]" : "h-fit"
        } tree-info !overflow-y-auto absolute z-10 shadow-2xl rounded-md text-center bg-white px-4 py-1  ${
          props.scroll
            ? "top-[20px]"
            : "bottom-[0px]" && props.index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        <thead>
          <tr className="border-b border-b-gray-200">
            <th className="w-32">{t("Plantings.Species")}</th>
            <th>Nr.</th>
          </tr>
        </thead>
        <tbody>
          {props.trees.map((tree, index) => (
            <tr className="text-[#939393]" key={index}>
              <td className="py-2">
                {i18n.language === "fr"
                  ? tree.species_name_transes.fr || tree.species_name_transes.en
                  : tree.species_name_transes.en}
              </td>
              <td>{tree.register_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TreesDetail;
